import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "./blocks/button";
import { motion, useMotionValue, animate } from "framer-motion";
import { useFlubber } from "../use-flubber";

const SlideWrapper = styled.div`
  filter: ${(props) =>
    props.current
      ? "grayscale(0) hue-rotate(0) blur(0)"
      : "grayscale(20%) hue-rotate(200deg) blur(5px)"};
  transition: filter 0.6s ease-in;
  min-width: 100%;

  @media (min-width: 768px) {
    min-width: 85%;
    margin-right: 50px;
    overflow: hidden;
  }

  @media (min-width: 1200px) {
    margin-right: 100px;
  }

  @media (min-width: 1200px) {
    min-width: 65%;
  }
`;

const SlideInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 100vh;
  @media(min-width: 1200px) {
    padding: 50px 20px;
    min-height: 0;
    overflow: hidden;
  }
`;

const SlideTop = styled.div`
  display: none;

  @media (min-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    width: calc(100% - 100px);

    .number {
      font-size: 64px;
    }
  }
`;
const SlideMain = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
const SlideIcon = styled.div`
  .svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  .gatsby-image-wrapper {
    height: 40vh;
  }

  @media (min-width: 768px) {
    .gatsby-image-wrapper {
    height: 50vh;
  }
  }

  @media (min-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    
    .gatsby-image-wrapper {
      aspect-ratio: 1 / 1;
      max-height: 70vh;
      height: auto;
      -webkit-clip-path: ${(props) => `url(#${props.clipPath})`};
      clip-path: ${(props) => `url(#${props.clipPath})`};
    }
  }
`;

const SlideContent = styled.div`
padding: 20px;
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 500px;
    padding: 2%;
  }

  @media(max-width: 1199px) {
    a {
      margin-bottom: 50px;
    }
  }
`;

const SlideBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #44475a;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    filter: blur(100px);
    opacity: 0.4;
  }
`;

const paths = [
  "M0.989,0.659 Q0.958,0.759,0.837,0.783 Q0.715,0.808,0.651,0.923 Q0.587,1,0.451,1 Q0.316,1,0.226,0.941 Q0.137,0.854,0.168,0.741 Q0.198,0.627,0.099,0.536 Q0,0.446,0.166,0.43 Q0.333,0.414,0.328,0.261 Q0.324,0.108,0.461,0.054 Q0.598,0,0.711,0.087 Q0.824,0.174,0.957,0.235 Q1,0.296,1,0.427 Q1,0.558,0.989,0.659",
  "M0.926,0.977 c-0.1,0.066,-0.383,-0.023,-0.537,-0.109 c-0.072,-0.04,-0.157,-0.106,-0.23,-0.177 C0.076,0.61,0.01,0.523,0.001,0.463 c-0.017,-0.114,0.139,-0.202,0.285,-0.277 C0.432,0.11,0.762,-0.039,0.879,0.009 c0.053,0.022,0.079,0.086,0.093,0.164 c0.017,0.095,0.014,0.212,0.019,0.301 c0.008,0.161,0.035,0.437,-0.065,0.503",
  // "M0.976,0.998 c-0.104,0.068,-0.361,0.016,-0.617,0.008 c-0.075,-0.042,-0.338,0.08,-0.319,-0.014 c0.043,-0.212,0.002,-0.455,-0.008,-0.518 c-0.008,-0.131,-0.06,-0.316,0.078,-0.397 c0.152,-0.079,0.665,0.051,0.837,-0.045 c0.055,0.023,-0.041,0.075,-0.012,0.154 c0.033,0.088,0.003,0.21,0.008,0.302 c0.008,0.168,0.137,0.441,0.033,0.51",
];

export default function Slide({ index, slide, checker, current, slidecheck }) {
  const [active, setActive] = useState(false);

  const num = checker ? 1 : 0;
  const progress = useMotionValue(num);
  const path = useFlubber(progress, paths);

  useEffect(() => {
    const animation = animate(progress, num, {
      duration: 0.4,
      type: "spring",
      stiffness: 30,
      mass: 1,
      onComplete: () => {
        if (checker) {
          progress.set(1);
          animation.stop();
        } else {
          progress.set(0);
          animation.stop();
        }
      },
    });

    return () => animation.stop();
  }, [checker, num, progress]);
  // useEffect(() => {
  //    setActive(!active)

  // }, [checker]);

  return (
    <SlideWrapper
      className="embla__slide"
      key={index}
      checker={checker}
      current={checker}
      slidecheck={slidecheck}
    >
      <SlideInner>
        <SlideTop>
          <div className="minititle">
            0{index + 1} / {slide.subtitle}
          </div>
          <div className="number">0{index + 1}</div>
        </SlideTop>
        <SlideMain>
          <SlideIcon clipPath={`my-clip-path-${index}`}>
            <GatsbyImage
              image={getImage(slide.image.asset)}
              alt={slide.image.asset.altText}
              loading="eager"
            />
            <svg className="svg" key={index}>
              <clipPath id="mobile-clip-path" clipPathUnits="objectBoundingBox">
                <motion.path d="M0.926,0.977 c-0.1,0.066,-0.383,-0.023,-0.537,-0.109 c-0.072,-0.04,-0.157,-0.106,-0.23,-0.177 C0.076,0.61,0.01,0.523,0.001,0.463 c-0.017,-0.114,0.139,-0.202,0.285,-0.277 C0.432,0.11,0.762,-0.039,0.879,0.009 c0.053,0.022,0.079,0.086,0.093,0.164 c0.017,0.095,0.014,0.212,0.019,0.301 c0.008,0.161,0.035,0.437,-0.065,0.503" />
              </clipPath>
            </svg>
            <svg className="svg" key={index}>
              <clipPath
                id={`my-clip-path-${index}`}
                clipPathUnits="objectBoundingBox"
              >
                <motion.path d={path} />
              </clipPath>
            </svg>
          </SlideIcon>

          <SlideContent>
            <h2>{slide.title}</h2>
            <PortableText value={slide.content} />
            <PortableText value={slide.link} />
            <Button url={slide.link.slug.current} text={slide.buttontext} />
          </SlideContent>
        </SlideMain>
        <SlideBG>
          <GatsbyImage
            image={getImage(slide.image.asset)}
            alt={slide.image.asset.altText}
          />
        </SlideBG>
      </SlideInner>
    </SlideWrapper>
  );
}
